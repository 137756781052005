<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="logo">
        <!-- You can replace this img with your logo -->
        <img src="https://res.cloudinary.com/fsquaredmedia/image/upload/v1707436554/murphy-2.png" alt="Logo" />
      </div>
      <div class="links">
        <a href="#" class="footer-link">The Insider Exclusive</a><span class="divider">|</span>
        <a href="#" class="footer-link">The LawBusiness Insider</a><span class="divider">|</span>
        <a href="#" class="footer-link">Amazon Books</a><span class="divider">|</span>
        <div class="copyright">
          © {{ new Date().getFullYear() }} PRI Inc. All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.footer {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #000;
  color: white;
  font-size: 16px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.divider {
  margin: 0 5px;
}

.logo img {
  max-width: 300px; /* Adjust based on your logo size */
}

.links {
  display: flex;
  align-items: center;
}

.footer-link {
  /*margin-left: 20px;*/
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.footer-link:first-child {
  margin-left: 0;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .links {
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-link {
    margin: 5px 0;
  }
}
</style>
