<template>
  <div class="app-jumbotron"
    :style="{
      backgroundImage: backgroundImage
    }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    backgroundImage: String
  },
  data () {
    return {}
  },
  mounted () {}
}
</script>
<style scoped lang="scss">
.app-jumbotron {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #182b3a;
  // background-image: url(https://res.cloudinary.com/fsquaredmedia/image/upload/c_fill,q_64,w_1920/v1707320497/sharegrid-7IBigEuL9Tw-unsplash.jpg);
  // background-image: linear-gradient(to top, rgba(4, 21, 38, 0.5), rgba(12, 47, 160, 0.5)), url('https://res.cloudinary.com/fsquaredmedia/image/upload/c_fill,q_64,w_1920/v1707320497/sharegrid-7IBigEuL9Tw-unsplash.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;

  .jumbotron-actions {
    display: flex;
    align-items: center;
    top: 15px;
    right: 15px;
    position: absolute;
  }

  .jumbotron-cta {
    background-color: transparent;
    border: 2px solid white;
    padding: 10px 20px;
    font-size: 22px;
    text-decoration: none;
    // border-radius: 12px;
    color: white;
    max-width: 150px;
    text-align: center;
    margin-right: 8px;
  }

  .jumbotron-social {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .jumbotron-social svg {

  }

  .jumbotron-social svg.social-twitter {
    width: 60px;
    height: 60px;
  }

  .jumbotron-social svg.social-twitter {
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg); /* Standard syntax */
  }

  .jumbotron-social svg.social-facebook {
    -webkit-transform: scaleY(-1); /* Chrome, Safari, Opera */
    transform: scaleY(-1); /* Standard syntax for vertical flip */
    width: 50px;
    height: 50px;
  }

  .image-logo {
    max-width: 300px;
    margin-top: 20px;
  }

  h1, h2, h3 {
    color: white;
    font-size: 48px;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    margin-top: 5px;
  }

  h1, h3 {
    font-weight: 800;
  }

  h2 {
    font-style: italic;
    font-weight: 800;
  }
}
</style>
