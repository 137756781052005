<template>
  <a :href="href" :target="target" class="link-button">{{ text }}</a>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: '_self'
    }
  }
}
</script>

<style>
.link-button {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: currentColor;
  border: 2px solid #fff;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 22px;
  color: white;
  max-width: 230px;
  width: 100%;
  text-align: center;
  color: #fff;
}

.link-button:hover {
  background-color: #fff;
  color: #000;
}
</style>
