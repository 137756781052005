import { render, staticRenderFns } from "./AppJumbotron.vue?vue&type=template&id=4ddb7512&scoped=true"
import script from "./AppJumbotron.vue?vue&type=script&lang=js"
export * from "./AppJumbotron.vue?vue&type=script&lang=js"
import style0 from "./AppJumbotron.vue?vue&type=style&index=0&id=4ddb7512&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ddb7512",
  null
  
)

export default component.exports